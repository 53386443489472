<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @open-sidebar-report="openSideBarReport"
        @missing-event="missingEvent"
      />
      <calendar-report-handler
        v-model="isReportHandlerSidebarActive"
        :report="report"
        @add-report="addReport"
        @update-report="updateReport"
      />
    </div>
    <b-row class="mt-50">
      <b-col md="12" class="mx-auto">
        <time-line-calendar
          @refetch-events="refetchEvent"
          @open-sidebar-report="openSideBarReport"
          @missing-event="missingEvent"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "@axios";
import FullCalendar from "@fullcalendar/vue";
import { BRow, BCol } from "bootstrap-vue";

import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import calendarStoreModule from "./calendarStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import CalendarReportHandler from "./calendar-report-handler/CalendarReportHandler.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TimeLineCalendar from "./timeline-calendar/TimeLineCalendar.vue";
import useCalendar from "./useCalendar";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    CalendarReportHandler,
    TimeLineCalendar,
    BRow,
    BCol,
  },

  watch: {
    "$store.state.calendar.sideBarInfo"(val) {
      this.isEventHandlerSidebarActive = val.isEventHandlerSidebarActive;
      if (val.isEventHandlerSidebarActive) this.event = val.event;
    },
    "$store.state.calendar.sideBarReport"(val) {
      this.isReportHandlerSidebarActive = val.isReportHandlerSidebarActive;
      if (val.isReportHandlerSidebarActive) this.report = val.report;
    },
    $route(val) {
      if (val.params.aluno != undefined)
        this.$store.commit("calendar/SET_ALUNO", val.params.aluno);
      else this.$store.commit("calendar/SET_ALUNO", null);

      this.refetchEvent();
    },
  },

  methods: {
    refetchEvent() {
      this.refetchEvents();
    },

    async openSideBarReport(event) {
      const questions = await axios.get("/calendar/relatorio/questoes", {
        params: {
          type: event.extendedProps.scheduleType,
        },
      });

      this.$store.commit("calendar/SET_SIDEBARREPORT", {
        isReportHandlerSidebarActive: true,
        report: event,
        fields: questions.data.questoes,
      });
    },

    async missingEvent(event) {
      const status = await this.$bvModal.msgBoxConfirm(
        "Por favor, confirme que você deseja marcar falta para o aluno nesta aula, ação irreversível.",
        {
          title: "Tem certeza desta ação?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        }
      );

      if (status) {
        try {
          await this.$store.dispatch("calendar/missingEvent", {
            event: event,
          });
          this.refetchEvent();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Sucesso",
              text: "Aula marcada como falta do aluno com sucesso",
              icon: "ThumbsUpIcon",
              variant: "success",
            },
          });
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Erro",
              text: err.response.data.message,
              icon: "ThumbsDownIcon",
              variant: "danger",
            },
          });
        }
      }
    },
  },

  created() {
    if (this.$route.params.aluno != undefined)
      this.$store.commit("calendar/SET_ALUNO", this.$route.params.aluno);
    else this.$store.commit("calendar/SET_ALUNO", null);
  },

  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const isReportHandlerSidebarActive = false;
    const report = {};

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addReport,
      updateReport,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar();

    fetchEvents();

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      report,
      clearEventData,
      addReport,
      updateReport,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isReportHandlerSidebarActive,
    };
  },
};
</script>

<style>
.fc-event-title-container {
  font-weight: normal !important;
}
.fc-event-time {
  font-weight: bold;
  padding-right: 4px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
